<template>
  <b-modal
    id="add-new-card"
    ref="add-new-card"
    body-class="p-10"
    centered
    title="Adding New Credit Card"
    @ok="submitCard"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-new-card"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <credit-card-cardknox-template
        v-if="isCardknox"
        ref="cardknoxTemplate"
        :i-fields-options="iFieldsOptions"
        :error-expiry="errorExpiry"
        :card-expire="cardExpiry"
        @updateExpire="checkCardExpiration"
      />

      <credit-card-stripe-template
        v-if="isStripe"
        :error-cvc="errorCvc"
        :error-expiry="errorExpiry"
        :error-number="errorNumber"
        :has-card-error="hasCardError"
        :stripe-item-i-d="stripeItemID"
      />

    </b-overlay>
    <template #modal-footer="{ cancel, ok }">
      <div class="w-100 d-flex justify-content-between">
        <b-button
          class="border border-primary"
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import { BModal, BButton, BOverlay } from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js'
import CreditCardStripeTemplate from '@/components/site/CreditCardStripeTemplate.vue'
import uniqueId from 'lodash/uniqueId'
import CreditCardCardknoxTemplate from '@/components/site/CreditCardCardknoxTemplate.vue'

export default {
  components: {
    CreditCardCardknoxTemplate,
    CreditCardStripeTemplate,
    BModal,
    BButton,
    BOverlay,
  },
  data() {
    return {
      stripeItemID: '',
      cardknoxItemID: '',
      userId: getUserData().id,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      errorNumber: null,
      errorExpiry: null,
      errorCvc: null,
      cardToken: null,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'paymentPortal/getIsModalLoaidng',
      publishableKey: 'paymentSettings/getStripePublishKey',
      isCardknox: 'paymentSettings/getIsCardknox',
      isStripe: 'paymentSettings/getIsStripe',
      iFieldsOptions: 'paymentSettings/getCardknoxIFieldsOptions',
    }),
    hasCardError() {
      return !!this.errorNumber || !!this.errorExpiry || !!this.errorCvc
    },
  },
  beforeDestroy () {
    if (this.cardNumber) {
      this.cardNumber.destroy()
    }
    if (this.cardExpiry) {
      this.cardExpiry.destroy()
    }
    if (this.cardCvc) {
      this.cardCvc.destroy()
    }
    this.errorNumber = null
    this.errorExpiry = null
    this.errorCvc = null
    this.cardExpiry = ''
  },
  methods: {
    async checkCardExpiration(newValue) {
      this.cardExpiry = newValue
    },
    async setModalData() {
      if (this.isStripe) {
        await this.prepareStripeElements()
      }
      if (this.isCardknox) {
        await this.prepareCardknoxElements
      }
    },
    async prepareCardknoxElements() {
      this.cardknoxItemID = uniqueId()
    },
    async prepareStripeElements() {
      this.stripeItemID = uniqueId()

      // Load Stripe.js with your publishable key
      this.stripe = await loadStripe(this.publishableKey)
      const elements = this.stripe.elements()

      const style = {
        style: {
          base: {
            fontSize: '16px',
            color: process.env.SECONDARY_COLOR,
            backgroundColor: '#EDEDED',
            '::placeholder': {
              color: process.env.SECONDARY_COLOR,
            },
          },
        },
      }

      // Create and mount the card number input field
      this.cardNumber = elements.create('cardNumber', style)
      this.cardNumber.mount('#card-number-' + this.stripeItemID)
      this.cardNumber.addEventListener('change', (event) => {
        if (event.error) {
          this.errorNumber = event.error.message
        } else {
          this.errorNumber = null
        }
      })


      this.cardExpiry = elements.create('cardExpiry', style)
      this.cardExpiry.mount('#card-expiry-' + this.stripeItemID)
      this.cardExpiry.addEventListener('change', (event) => {
        if (event.error) {
          this.errorExpiry = event.error.message
        } else {
          this.errorExpiry = null
        }
      })

      this.cardCvc = elements.create('cardCvc', style)
      this.cardCvc.mount('#card-cvc-' + this.stripeItemID)
      this.cardCvc.addEventListener('change', (event) => {
        if (event.error) {
          this.errorCvc = event.error.message
        } else {
          this.errorCvc = null
        }
      })
    },
    async submitCard(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isCardknox) {
        await this.handleCardknoxPayment()
      }
      if (this.isStripe) {
        await this.handleStripePayment()
      }
    },
    async handleStripePayment() {
      if (this.hasCardError) {
        return
      }

      const token = await this.fetchStripeCardToken()

      token.gateway = 'stripe'

      await this.saveTokenToServer(token)
    },
    async saveTokenToServer(token) {
      token.user_id = this.userId

      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/createNewCreditCard', token)
      await store.dispatch('paymentPortal/fetchCreditCardsList')
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
      this.$refs['add-new-card'].hide()
    },
    async fetchStripeCardToken() {
      const { token, error } = await this.stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById('#' + this.stripeItemID + 'card-error').innerHTML = error.message
        return
      }

      return token
    },
    async fetchCardknoxCardToken() {
      return new Promise((resolve) => {
        this.$refs.cardknoxTemplate.$refs.ifieldCardRef._onToken = (response) => {
          this.cardToken = response?.data?.xToken || ""
          resolve(this.cardToken)
        }
        this.$refs.cardknoxTemplate.$refs.ifieldCardRef.getToken()
      })
    },
    async handleCardknoxPayment() {
      if (!this.$refs.cardknoxTemplate.$refs.ifieldCardRef) {
        return
      }

      await this.fetchCardknoxCardToken()

      if (!this.cardToken || !this.cardExpiry) return

      try {
        const cardNumber = this.cardToken.split(';')[0]
        const match = this.cardToken.match(/cc_(.*?)_keyed/)
        const cardType = match ? match[1] : 'unknown'

        const [month, year] = this.cardExpiry.split('/');

        await this.saveTokenToServer({
          id: this.cardToken,
          card: {
            exp_month: month,
            exp_year: year,
            last4: cardNumber.slice(-4),
            brand: cardType,
          },
          gateway: 'cardknox',
        })
      } catch (error) {
        // document.getElementById('#' + this.cardknoxItemID + 'card-error').innerHTML = error.message
        console.error('Ошибка токенизации:', error)
      }
    },
    resetModal() {
      if (this.cardNumber) {
        this.cardNumber.destroy()
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy()
      }
      if (this.cardCvc) {
        this.cardCvc.destroy()
      }
    },
  }
}
</script>

<style>

</style>