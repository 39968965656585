<template>
  <b-tab 
    title="Payment Contracts "
    title-item-class="payment-portal__tab"
  >
    <template
      v-for="(item, index) in allPayConditions"
    >
      <b-card
        :key="index"
        class="mb-3"
      >
        <template #header>
          <h3
            class="d-flex align-items-center mb-0 lead"
          >
            <span>
              {{item.application.program.name}}
            </span>
            <b-badge
              class="ml-2 text-secondary payment-portal__light-badge"
              pill
            >{{ item.application.user.full_name }}</b-badge>
          </h3>
        </template>
        <b-card-body
          class="bg-light"
        >
          <h5
            v-if="item.condition_type == 'PaymentOption'"
            class="text-dark mb-3"
          >
            <span v-if="item.application.payment_contract"> Payment Contract: {{item.application.payment_contract.name}} </span>
            <span v-else> Payment Contract: custom </span>
          </h5>
          <h5
            v-if="item.condition_type == 'PaymentPlan'"
            class="text-dark mb-3"
          >
            <span>Payment Plan </span>
          </h5>
          <h5
            v-if="item.condition_type == null"
            class="text-dark mb-3"
          >
            <span>Scheduled Manual Upcoming Payment </span>
          </h5>
          <div class="d-flex flex-wrap">
            <div class="payment-option__item mr-3">
              <PercentSVG />
              <span>Amount $ {{item.transaction_sum}}</span>
            </div>

            <template
              v-if="item.payment_option && item.payment_option.schedule_option.timeSchedule == 'Split over'"
            >
              <div class="payment-option__item mr-3">
                <SplitSVG />
                <span>Split over {{ item.count_of_payments }} month</span>
              </div>

              <div class="payment-option__item mr-3">
                <TimerSVG />
                <span>Start Date {{ formatDate(item.start_date) }}</span>

              </div>
              <div class="payment-option__item mr-3">
                <TimeSVG />
                <span>Ending {{formatDate(item.payment_option.schedule_option.endDate)}}</span>
              </div>
            </template>

            <template
              v-else
            >
              <div class="payment-option__item mr-3">
                <FileSVG />
                <span>Charge Immediately</span>
              </div>
            </template>
            <div class="payment-option__item mr-3">
              <CreditCardSVG />
              <span>{{item.payment_method}}</span>
            </div>
          </div>

          <div class="overflow-auto">
            <b-table
              ref="refPaymentContractsTable"
              :items="item.application_pay_condition_transactions"
              :fields="paymentContractsTableColumns"
              :busy="isBusy"
              thead-class="table-header"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative w-100"
            >

              <!-- Loader -->
              <template #table-busy>
                <div class="d-flex justify-content-center">
                  <b-spinner
                    class="align-middle"
                  />
                </div>
              </template>

              <!-- Column: Id -->
              <template #cell(id)="data">
                <span :id="data.item.id">{{ data.item.id }}</span>
              </template>

              <!-- Column: amount -->
              <template #cell(amount)="data">
                {{ data.value | fixAmount }}
              </template>

              <!-- Column: Date -->
              <template #cell(pay_date)="data">
                <span>{{ data.item.pay_date | shortDate }}</span>
              </template>
            </b-table>
          </div>

        </b-card-body>
      </b-card>
    </template>
  </b-tab>
</template>

<script>
import {BCard, BCardBody, BTab, BBadge, BTable, BSpinner} from 'bootstrap-vue'

import usePaymentContracts from '@/views/PaymentPortal/PaymentContracts/usePaymentContracts'
import { filters } from '@core/mixins/filters'
import { getFormatDate } from '@/helpers/dateFormater'
import PercentSVG from '@/assets/images/icons/percent.svg'
import SplitSVG from '@/assets/images/icons/split.svg'
import TimerSVG from '@/assets/images/icons/timer.svg'
import TimeSVG from '@/assets/images/icons/time.svg'
import FileSVG from '@/assets/images/icons/file.svg'
import CreditCardSVG from '@/assets/images/icons/credit-card.svg'


export default {
  name: "PaymentContractsDetailsTab",
  components: {
    BSpinner,
    BTable,
    BCard,
    BCardBody,
    BTab,
    BBadge,

    PercentSVG,
    SplitSVG,
    TimerSVG,
    TimeSVG,
    FileSVG,
    CreditCardSVG,
  },
  mixins: [ filters ],
  data() {
    return {
      allPayConditions: [],
      isBusy: false,
    }
  },
  setup() {
    const {
      fetchPaymentContract,
    } = usePaymentContracts()

    return {
      fetchPaymentContract,
    }
  },
  async created() {
    await this.fetchPaymentContracts()
  },
  computed: {
    paymentContractsTableColumns() {
      return [
        { key: 'id', label: 'Id' },
        { key: 'amount', label: 'Amount' },
        { key: 'pay_date', label: 'Payment Date' },
        { key: 'payment_method', label: 'Payment Method' },
        { key: 'status', label: 'Status' },
      ]
    },
  },
  methods: {
    async fetchPaymentContracts() {
      const queryParams = {
        condition_type: 'PaymentOption'
      }
      await this.fetchPaymentContract(queryParams)
        .then(response => {
          this.allPayConditions = response
        })
    },
    formatDate(date) {
      return getFormatDate(date)
    }
  },
}
</script>
